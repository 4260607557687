


























































































































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import {
  BContainer, BForm, BFormInput, BIconInfoCircle,
} from 'bootstrap-vue';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AlertComponent from '@/components/AlertComponent.vue';
import AlertType from '@/utils/enums/AlertType';
import InputText from '@/components/InputText.vue';
import LoginFormInterface from '@/utils/types/LoginFormInterface';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityUser from '@/models/graphql/CommunityUser';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { EMAIL } from '@/utils/constants/Regex';

const authenticationStore = namespace('AuthenticationStore');
const emailValidation = helpers.regex('email', EMAIL);

@Component({
  components: {
    AvatarSoloWidget,
    InputText,
    ButtonComponent,
    BContainer,
    BForm,
    BFormInput,
    BIconInfoCircle,
    AlertComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(1),
        emailValidation,
      },
      password: {
        required,
        minLength: minLength(3),
      },
      remember: {},
    },
  },
  data(): object {
    return {
      emailError: false,
      passwordError: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'new-password'
      || from.name === 'find-account-email'
      || from.name === 'reset-password-email'
      || from.name === 'register'
      || from.name === 'reset-password'
      || (to.name === 'login' && to.query?.redirect)
      || from.name === 'login') {
      next();
    } else {
      const isLoginPathHasRedirection = from.name !== null
        && from.name !== 'register'
        && from.name !== 'reset-password-email'
        && from.name !== 'find-account-email'
        && from.name !== 'create-account';
      if (isLoginPathHasRedirection) {
        next((vm) => {
          const redirect = encodeURIComponent(vm.$router.resolve({
            params: from.params,
            path: from.path,
            query: from.query,
          }).href);
          vm.$router.replace(vm.$router.resolve({
            params: to.params,
            path: to.path,
            query: {
              ...to.query,
              redirect,
            },
          }).location);
        });
      }
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'register') {
      next(false);
    } else {
      next();
    }
  },
})
export default class LoginPage extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: '',
  })
  message!: string;

  @Prop({
    required: false,
    default: '',
  })
  errorMessage!: string;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected FeatureKeys = FeatureKeys;

  @Getter
  private isLoading!: boolean;

  private form: LoginFormInterface = {
    email: null,
    password: null,
    remember: false,
  };

  private remember = false;

  private alertType = AlertType;

  private FileResourceHelper = FileResourceHelper;

  @authenticationStore.Action
  private login!: (credentials: {
    username: string; password: string; remember: boolean;
  }) => Promise<CommunityUser | null>;

  @authenticationStore.Action
  private resetAuthErrors!: () => void;

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @authenticationStore.State
  private authErrors!: string[];

  @authenticationStore.Getter
  private fetchJustSignedOut!: boolean;

  @authenticationStore.Action
  private resetJustSignedOut!: () => void;

  private get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.community?.logoFileResource), 'w96');
    }
    return '';
  }

  private get enableSignup(): boolean {
    return this.featureByKey(FeatureKeys.USER_SIGNUP)
      && this.featureByKey(FeatureKeys.USER_SIGNUP).enabled;
  }

  private get enableRegister(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE).enabled
      && Boolean(this.community.organizer?.registrationWebsite);
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    if (this.isAuthenticated) {
      this.$router.replace({ path: '/' });
    }
    this.resetAuthErrors();
  }

  onSubmit(): void {
    this.login({
      username: this.form.email ? this.form.email.toLowerCase() : '',
      password: this.form.password ?? '',
      remember: this.form.remember ?? false,
    })
      .then((response) => {
        if (response) {
          this.$logger.log(['trackEvent', StatLoggerCategories.ACCOUNT, StatLoggerActions.SIGN_IN_SUCCESS, null, null, {
            ...this.$logger.serialiseAuthUserDimensions(response, this.community.code ?? '', ''),
            dimension2: this.$i18n.locale,
          }]);
        } else {
          this.$logger.log(['trackEvent', StatLoggerCategories.ACCOUNT, StatLoggerActions.SIGN_IN_FAILURE, null, null, {
            ...this.$logger.serialiseAuthUserDimensions(null, this.community.code ?? '', ''),
            dimension2: this.$i18n.locale,
          }]);
        }
      });
  }

  public routeToResetPassword(): void {
    this.onAlertClose();
    this.$router.push({ name: 'reset-password-email' });
  }

  private onAlertClose(): void {
    if (this.authErrors.length !== 0) {
      this.resetAuthErrors();
    } else {
      this.resetJustSignedOut();
    }
  }

  private disabledSubmit(): boolean {
    return this.$v.$invalid;
  }

  private onBlur(fieldType: string): void {
    if (this.$v) {
      switch (fieldType) {
        case 'email':
          this.$data.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
          break;
        case 'password':
          this.$data.passwordError = this.$v.form.password ? this.$v.form.password.$invalid : false;
          break;
        default:
          break;
      }
    }
  }

  private onSignupClick(): void {
    this.$router.replace({ name: 'create-account' });
  }

  private onRegisterClick(): void {
    window.open(this.community.organizer?.registrationWebsite, '_blank');
  }
}
